import Decimal from "decimal.js";

/**
 * 将普通小数转化为展示的价格（后面补齐两位小数）
 * @param {string | number} amount 
 * @returns 
 */
export const getDisplayAmount = (amount) => {
  let result = "";
  const amountStr = amount + "";
  if (amountStr.includes(".")) {
    const amountSpl = amountStr.split(".");
    if (amountSpl.length && amountSpl.length === 2) {
      const decimalPart = (amountSpl[1] + "000000").slice(0, 2);
      result = amountSpl[0] + "." + decimalPart;
    } else {
      console.error("Error in getDusokayAmount() in display.js in utils");
    }
  } else {
    result = amountStr + ".00";
  }
  return result;
};

/**
 * 统计当日收支总和
 * @param {array} billList 账单列表 
 * @param {*} date 统计的日期 2024-03-26
 * @returns 
 */
export const getDailyTotal = (billList = [], date = "") => {
  const spendingList = billList.filter(bill => bill.date === date && bill.method === "spending");
  const incomeList = billList.filter(bill => bill.date === date && bill.method === "income");

  let sp = new Decimal(0);
  spendingList.forEach(spBill => {
    sp = sp.plus(spBill.amount);
  });
  let inc = new Decimal(0);
  incomeList.forEach(incBill => {
    inc = inc.plus(incBill.amount);
  });
  return {
    income: inc,
    spending: sp
  };
}
<template>
  <div class="selected-type">
    <div class="icon">
      <i :class="handleGetIcon()" :style="handleGetIconStyle()"></i>
    </div>
    <div class="label" :style="handleGetTextBgStyle()">
      <span style="color: white">
        {{ handleGetTypeName() }}
      </span>
    </div>
    <div class="close" v-if="showClose">
      <i class="el-icon-close" @click="handleCloseBillType()" />
    </div>
    <div class="setting" v-if="showSetting">
      <i class="el-icon-setting" @click="handleEdit()"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "BillTypeItem",
  props: {
    billTypeData: {
      type: Object,
      default: () => {
        return null;
      },
    },
    billTypeId: {
      type: String,
      default: () => {
        return "";
      },
    },
    billTypeList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    showClose: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    showSetting: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  methods: {
    /**
     * 生成賬單類型的圖標icon
     * @returns 賬單圖標el-icon
     */
    handleGetIcon() {
      let res = "";
      if (this.billTypeData) {
        res = this.billTypeData.icon;
      } else {
        const one = this.billTypeList.find(
          (item) => item.id === this.billTypeId
        );
        if (one) {
          const { icon } = one;
          if (icon) {
            res = `${icon}`;
          }
        }
      }

      return res;
    },

    /**
     * 產生帳單類型圖示的顏色style
     * @returns 樣式內容
     */
    handleGetIconStyle() {
      let res = "";
      if (this.billTypeData) {
        if (this.billTypeData.color) {
          res = `color: ${this.billTypeData.color}`;
        }
      } else {
        const one = this.billTypeList.find(
          (item) => item.id === this.billTypeId
        );
        if (one) {
          const { color } = one;
          if (color) {
            res = `color: ${color}`;
          }
        }
      }
      return res;
    },
    /**
     * 產生帳單類型的背景顏色
     * @returns 樣式內容
     */
    handleGetTextBgStyle() {
      let res = "";
      if (this.billTypeData) {
        if (this.billTypeData.color) {
          res = `background-color: ${this.billTypeData.color}99`;
        }
      } else {
        const one = this.billTypeList.find(
          (item) => item.id === this.billTypeId
        );
        if (one) {
          const { color } = one;
          if (color) {
            res = `background-color: ${color}99`;
          }
        }
      }
      return res;
    },

    /**
     * 生成賬單類型的名字
     * @returns 賬單類型的名字
     */
    handleGetTypeName() {
      let res = "";
      if (this.billTypeData) {
        if (this.billTypeData.name) {
          res = `${this.billTypeData.name}`;
        }
      } else {
        const one = this.billTypeList.find(
          (item) => item.id === this.billTypeId
        );
        if (one) {
          const { name } = one;
          if (name) {
            res = name;
          }
        }
      }
      return res;
    },

    /**
     * 點擊刪除賬單類型
     */
    handleCloseBillType() {
      this.$emit("handleCloseBillType");
    },
    /**
     * 點擊编辑賬單類型
     */
    handleEdit() {
      this.$emit("handleEdit");
    },
  },
};
</script>

<style lang="scss" scoped>
.selected-type {
  height: 35px;
  margin: 0px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 8px 0px #adadad;
  user-select: none;
  width: fit-content;
  position: relative;

  .icon {
    height: 100%;
    width: 35px;
    // border-right: 1px solid #bbbbbb;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    background-color: #fff;
  }
  .label {
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
  }
  .close {
    height: 100%;
    width: 35px;
    // border-right: 1px solid #bbbbbb;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: #fff;
    color: #999999;
    transition: all 0.3s;
    cursor: pointer;
  }
  .setting {
    height: 100%;
    width: 35px;
    // border-right: 1px solid #bbbbbb;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: #fff;
    color: #999999;
    transition: all 0.3s;
    cursor: pointer;
  }
  .setting:hover {
    color: #188cff;
  }
}
</style>
